export const Steps_Of_Production = "/production-step/add-step";
export const List_Steps_Of_Production = "/production-step/view-steps/";
export const Edit_Steps_Of_Production = "/production-step/edit-inner-step";
export const Delete_OneSteps_Of_Production =
  "/production-step/delete-inner-step";

export const Start_Production = "/production-steps/start-production";
export const Get_Prodution = "/production-steps/view-ProductionList";
export const Update_Production = "/production-steps/nested-update-production/";
export const Delete_Production = '/production-steps/delete-nested-data/';
export const Update_Steps = '/rowProduct/update-steps'
export const View_Current_Stock = '/rowProduct/view-current-stock/'
export const View_CurrentProduct_Stock = "/product/view-current-stock/";
export const View_Production_Stock = "/production-steps/current-target-product/";
export const Save_Production_Target = "/product-target/save"
export const View_AllProductin_Targets = "/product-target/view/";
export const View_TargetById = "/product-target/view-by-id/";
export const Update_TargetById = "/product-target/update-target/";
export const Delete_TargetById = "/product-target/delete-target/"











//production base url
export const Production_BASE_URL = "https://production-node.rupioo.com";  

//Assign Production

export const Product_assignFor_Production = "/production-assign/product-assign";
export const Product_assignFor_Production_Update =
  "/production-assign/update-product/";
export const Product_assignFor_Production_ViewOne =
  "/production-assign/view-by-id/";
export const Product_assignFor_Production_List =
  "/production-assign//view-product/";
export const Delete_Product_assignFor_Production_List =
  "/production-assign/delete-product/";
export const VieW_Product_assignFor_Production_List =
  "/production-assign/view-id-product/";
//end Assign Productin

// add Raw material
export const Add_Raw_Material = "/rowProduct/save-product";
export const List_Raw_Material = "/rowProduct/view-product/";
export const ViewOne_Raw_Material = "/rowProduct/view-product-by-id/";
export const Delete_Raw_Material = "/rowProduct/delete-product/";
export const Update_Raw_Material = "/rowProduct/update-product/";
// ending of add Row material
